.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header > h1 {
    text-decoration: underline;
     font-size: 35px
}

.header > img {
    margin-top: 10px;
     height: 50px; 
     object-fit: contain;
      width: 80px
}

.configurationPopup{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 15px;
    width: 100%;  
}

.configurationPopup2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}

/* .close:hover{
    background-color:red
} */

.popupButtonDiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 70px;
}

.popupButtonDiv > button{
    background-color: #00acc1;
    color: white

}

.labelButton{
    font-weight: 900;
     color: black
}

.cardIcon{
    padding-top: 8px;
    padding-bottom: 10px;
    margin-top: -10px;
    margin-bottom: -10px;
    width: 48px;
    height: 48px;
    padding-left: 10px;
}


.graph{
    margin-top: 10px;
    height: 380px;
    width: 100%;
    /* border: 1px solid teal; */
    border-radius: 80px;
}

.graphTop{
    display: flex;
    align-items:center;
    justify-content: space-evenly
}

.graphTopItems1{
    height: 45px;
    width: 660px;
    background-color: #1BACC0;
    border-radius: 500px;
    margin: 20px;
    /* margin-top: -95px; */
}

.graphTopItemsData {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column
}

.graphBottom{
    margin-top: 35px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border: 1px solid red;
    width: 110%;
    overflow: hidden;
    position:relative;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    /* border: 1px solid gray; */
    text-align: left;
    padding: 6px;
    border: none;
  }
  
  
  tr:nth-child() {
    background-color: #dddddd;
  }

 .bottom {
      /* padding: 30px;
      margin: 50px 20px 20px 0px; */
      width: 100%;
      display: flex;
      align-items: top;
      justify-content: space-between;
      /* border: 1px solid teal */
  }

  .bottomTable{
      width: 60%;
  }

  .bottomImageContainer {
      margin-top: -20px;
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 40%;
}

  .bottomImage{
      width: 100%;
      height: 340px;      
  }


  .shiftsResults {
    display: flex; 
    align-items: center;
    flex-direction: column;
    margin: 5px;
  }

  .topItemsWrapper {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    align-content: flex-start;
  }
.divTableWrapper {
    height: 325px;
}

.divTable {
    display: table;
    width: 100%; 
}

.divTableHeader {
    display: table-header-group;
    font-size: 1.3em;
    line-height: 1.7em;
    font-weight: 300;
}

.divTableBody {
    display: table-row-group;
    font-size: 1.3em;
    line-height: 1.7em;
    font-weight: 300;
}

.divTableRow {
    display: table-row
}

.divTableCell {
    display: table-cell
}

.svgIconStyle {
    width: 30px !important;
    height: 30px !important;
    margin: 0 !important;
}

.shiftResultTitle {
    font-size: 1.3em;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 300;
    color: #3C4858;
    margin: 10px;
}

.shiftResultContent {
    font-size: 1.3em;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 300;
    margin: 10px;
}
  
.shiftResultTableTd {
    border-right: 1px solid #3C4858;;
}
