/*!
* https://github.com/YouCanBookMe/react-datetime
*/
.rdt {
	position: relative;
}

.rdt .rdtPicker {
	-webkit-transition: all 150ms linear;
	-moz-transition: all 150ms linear;
	-o-transition: all 150ms linear;
	-ms-transition: all 150ms linear;
	transition: all 150ms linear;
	margin-top: -20px;
	visibility: hidden;
	display: block;
	opacity: 0;
}

.rdt.rdtOpen .rdtPicker {
	opacity: 1;
	visibility: visible;
	margin-top: 0;
}

/* Changed background-image */
.rdt input.form-control {
	border: 0;
	background-image: linear-gradient(#00acc1, #00acc1),
	linear-gradient(#d2d2d2, #d2d2d2);
	background-size: 0 2px, 100% 1px;
	background-repeat: no-repeat;
	background-position: center bottom, center calc(100% - 1px);
	background-color: rgba(0, 0, 0, 0);
	transition: background 0s ease-out;
	float: none;
	box-shadow: none;
	border-radius: 0;
	font-weight: 400;
	width: 100%;
	height: 36px;
	padding: 7px 0;
	font-size: 12px;
	line-height: 1.428571429;
	display: block;
	width: 100%;
	color: #555;
}

/* Changed background-image */
.rdt input.form-control:focus {
	outline: none;
	background-image: linear-gradient(#00acc1, #00acc1),
	linear-gradient(#d2d2d2, #d2d2d2);
	background-size: 100% 2px, 100% 1px;
	box-shadow: none;
	transition-duration: 0.3s;
}

.rdtPicker {
	display: none;
	position: absolute;
	width: 260px;
	padding: 4px;
	margin-top: 1px;
	z-index: 99999 !important;
	background: #fff;
	border-radius: 0.125rem;
	box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	min-width: 160px;
}

.rdtPicker:before {
	display: inline-block;
	position: absolute;
	width: 0;
	height: 0;
	vertical-align: middle;
	content: '';
	top: -5px;
	left: 10px;
	right: auto;
	color: #ffffff;
	border-bottom: 0.4em solid;
	border-right: 0.4em solid transparent;
	border-left: 0.4em solid transparent;
}

.rdtPicker:after {
	border-bottom: 0.4em solid #ffffff;
	border-right: 0.4em solid transparent;
	border-left: 0.4em solid transparent;
	content: '';
	display: inline-block;
	position: absolute;
	top: -5px;
	left: 10px;
}

.rdtPicker {
	display: block;
	top: 40px;
}

.rdtStatic .rdtPicker {
	box-shadow: none;
	position: static;
}

.rdtPicker .rdtTimeToggle {
	text-align: center;
	padding: 5px;
	border-radius: 4px;
}

.rdtPicker table {
	width: 100%;
	margin: 0;
	border-color: #ffffff !important;
	border-collapse: collapse;
}

.rdtPicker td,
.rdtPicker th {
	text-align: center;
	padding: 1px;
}

.rdtPicker td {
	cursor: pointer;
}

.rdtDay {
	height: 30px;
	line-height: 33px;
	width: 30px;
	text-align: center;
	padding: 0px;
	border-radius: 50%;
}

/* Changed background-color */
.rdtDay.rdtToday.rdtActive,
.rdtDay.rdtActive,
.rdtDay.rdtActive:hover {
	background-color: #00acc1 !important;
	color: #ffffff;
	box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
	0 7px 10px -5px rgba(156, 39, 176, 0.4);
}

.rdtDays tr .dow {
	border-bottom: 1px solid #e3e3e3;
	text-align: center;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: inherit;
	padding-bottom: 5px;
	padding-top: 10px;
}

.rdtDays tr .rdtOld,
.rdtDays tr .rdtNew {
	color: #bdbdbd;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
	background: #eeeeee;
	cursor: pointer;
}

.rdtPicker td.rdtToday {
	position: relative;
}

.rdtPicker td.rdtActive.rdtToday:before {
	border-bottom-color: #fff;
}

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
	background: none;
	color: #999999;
	cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
	color: #999999;
}

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
	background: none;
	color: #999999;
	cursor: not-allowed;
}

.rdtPicker .dow {
	width: 14.2857%;
	border-bottom: none;
}

.rdtPicker th.rdtSwitch {
	width: 50px;
	padding: 5px;
	border-radius: 4px;
}

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
	font-size: 21px;
	vertical-align: top;
	border-radius: 50%;
	line-height: 33px;
}

.rdtPicker .dow,
.rdtPicker th.rdtSwitch,
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev,
.rdtPicker .rdtTimeToggle {
	color: #00acc1;
}

.rdtPrev span,
.rdtNext span {
	display: block;
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-customer-select: none;
	/* Chrome/Safari/Opera */
	-khtml-customer-select: none;
	/* Konqueror */
	-moz-customer-select: none;
	/* Firefox */
	-ms-customer-select: none;
	/* Internet Explorer/Edge */
	customer-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
	background: none;
	color: #999999;
	cursor: not-allowed;
}

.rdtPicker thead tr:first-child th {
	cursor: pointer;
}

.rdtPicker thead tr:first-child th:hover {
	background: #eeeeee;
}

.rdtPicker button {
	border: none;
	background: none;
	cursor: pointer;
}

.rdtPicker button:hover {
	background-color: #eee;
}

.rdtPicker thead button {
	width: 100%;
	height: 100%;
}

td.rdtMonth,
td.rdtYear {
	height: 50px;
	width: 25%;
	cursor: pointer;
}

td.rdtMonth:hover,
td.rdtYear:hover {
	background: #eee;
}

.rdtCounters {
	display: inline-block;
}

.rdtCounters > div {
	float: left;
	width: 40px;
	font-weight: inherit;
	margin: 3px;
	border-radius: 50%;
}

.rdtCounters .rdtCounterSeparator {
	width: 0;
	border: 1px solid transparent;
}

.rdtCounter {
	height: 100px;
}

.rdtCounter {
	width: 40px;
}

.rdtCounter .rdtCount {
	padding: 7px;
	height: 40px;
	border: 1px solid transparent;
}

.rdtCounterSeparator {
	padding: 7px;
	line-height: 100px;
}

.rdtCounter .rdtBtn {
	line-height: 40px;
	cursor: pointer;
	display: block;
	border-radius: 50%;
	color: #eeeeee;
	-webkit-transition: all 60ms ease-in;
	-moz-transition: all 60ms ease-in;
	-o-transition: all 60ms ease-in;
	-ms-transition: all 60ms ease-in;
	transition: all 60ms ease-in;
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-customer-select: none;
	/* Chrome/Safari/Opera */
	-khtml-customer-select: none;
	/* Konqueror */
	-moz-customer-select: none;
	/* Firefox */
	-ms-customer-select: none;
	/* Internet Explorer/Edge */
	customer-select: none;
}

.rdtCounter .rdtBtn:hover {
	background: #eee;
	color: #797979;
}

.rdtCounter .rdtCount {
	font-size: inherit;
	line-height: 25px;
}

.rdtMilli {
	vertical-align: middle;
	padding-left: 8px;
	width: 48px;
}

.rdtMilli input {
	width: 100%;
	font-size: inherit;
	margin-top: 37px;
}

.rdtMonths,
.rdtYears {
	padding-bottom: 10px;
}

.rdtMonths .rdtMonth,
.rdtMonths .rdtYear,
.rdtYears .rdtMonth,
.rdtYears .rdtYear {
	display: inline-block;
	width: 56px;
	height: 56px;
	line-height: 56px;
	margin: 3px 3px;
	cursor: pointer;
	border-radius: 50%;
	text-align: center;
}

/* Changed background-color */
.rdtMonths .rdtMonth.rdtActive,
.rdtMonths .rdtYear.rdtActive,
.rdtYears .rdtMonth.rdtActive,
.rdtYears .rdtYear.rdtActive {
	background-color: #00acc1 !important;
	color: #ffffff;
}

/* Only what we changed*/
.rdtPicker td.rdtToday:before {
	content: '';
	display: inline-block;
	border-left: 7px solid transparent;
	border-bottom: 7px solid black;
}

/*
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #00acc1 !important;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
  color: #00acc1 !important;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
  color: #00acc1 !important;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  color: #00acc1 !important;

  font-size: 21px;
  vertical-align: top;
} */

/* See: https://stackoverflow.com/a/48270841 */
/*
.rdtOpen .form-control {
  background: linear-gradient(to right, #00acc1, #00acc1) 0px calc(100% - 0px) /
    calc(100% - 10px) 2px no-repeat !important;

} */
