.ReactTable .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after {
	font-family: Font Awesome\ 5 Free !important;
	/* padding-right: 100% */
}

.ReactTable .rt-thead .rt-th.-cursor-pointer.-sort-asc > div:first-of-type:after {
	font-family: Font Awesome\ 5 Free !important;
}

.ReactTable .rt-thead .rt-th.-cursor-pointer.-sort-desc > div:first-of-type:after {
	font-family: Font Awesome\ 5 Free !important;
}
