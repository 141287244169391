.bar-chart .ct-bar {
  stroke-width: 80px;
}

.ct-label {
  font-size: 14px;
  font-weight: 500;
}

.ct-bar-label {
  font-size: 14px;  
  color: #3C4858;
}